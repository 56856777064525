import axios from 'axios'
import { getJwtString } from '../../components/Auth'
import API from '../../components/API'
import querystring from 'querystring'

axios.defaults.headers.common['Content-Type'] = 'application/json'

/**
 * @param {Object} args - Argumentos a pasar en query params
 * @param {string} args.action - Nombre de la tabla pivote
 * @returns {Promise}
 */
export function getFromPivot (args) {
  let params = querystring.stringify(args)
  if (params) params = `?${params}`
  return axios.get(`${API('pivots')}${params}`, {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * @param {Object} args - Argumentos a pasar en query params
 * @param {string} args.action - Nombre de la tabla pivote
 * @param {Object} body - Cuerpo de la petición
 * @returns {Promise}
 */
export function putToPivot (args, body) {
  let params = querystring.stringify(args)
  if (params) params = `?${params}`
  return axios.put(`${API('pivots')}${params}`, body, {
    headers: {
      Authorization: getJwtString()
    }
  })
}

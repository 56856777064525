import React from 'react'
import './styles.scss'

export default function (props) {
  let containerclassname = 'radio-input'
  let className = 'custom-style'

  if (props.containerclassname) {
    containerclassname += ` ${props.containerclassname}`
  }
  if (props.className) {
    className += ` ${props.className}`
  }

  const properties = { ...props }
  delete properties.containerclassname
  delete properties.className

  return (
    <label className={containerclassname}>
      <input type='radio' {...properties} />
      <div className={className} />
    </label>
  )
}
